import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import uuid from 'uuid';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DialogflowApiService {

  sessionId: string; // sessao para enviar ao Dialogflow
  messages = new BehaviorSubject<Array<[]>>([]); // observable que vai ser constantamente lido pelo webchat component

  constructor(private http: HttpClient) {
    this.sessionId = localStorage.getItem('sessionId');
    // se nao tiver nenhum sessionId no localStorage, cria um para a conversa
    if (!this.sessionId) {
      // exemplo de um uuid.v4() - "d777efa6-55de-4f39-a8d4-562ddabb92d9"
      localStorage.setItem('sessionId', uuid.v4());
      this.sessionId = localStorage.getItem('sessionId');
    }
  }

  // atualiza o objeto de mensagens para ser captado no webchat component
  update(message) {
    // transforma urls em links clicaveis
    message.forEach(msg => {
      if (msg.message === 'text') {
        // percorre todas as mensagens do bot
        msg.text.text.forEach(function(part, index) {
          const newText = [];
          // verifica se a mensagem tem inicio de link, se nao tiver, nao faz nada
          if (part.includes('http') || part.includes('https') || part.includes('www')) {
            const txtSplit = part.split(' ');

            // percorre cada parte da mensagem para achar qual delas é um link
            txtSplit.forEach(splt => {
              if (splt.includes('http')) {
                if (splt.split('').pop() === '.') {
                  splt = splt.split('').splice(0, splt.length - 1).join('');
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="' + splt + '" target="_blank">Link</a>';
                  // adiciona a virgula no fim do link
                  splt = splt + '.';
                } else if (splt.split('').pop() === ',') {
                  splt = splt.split('').splice(0, splt.length - 1).join('');
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="' + splt + '" target="_blank">Link</a>';
                  // adiciona a virgula no fim do link
                  splt = splt + ',';
                } else {
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="' + splt + '" target="_blank">Link</a>';
                }
              } else if (!splt.includes('http') && splt.includes('www')) {
                if (splt.split('').pop() === '.') {
                  splt = splt.split('').splice(0, splt.length - 1).join('');
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="' + location.protocol + '//' + splt + '" target="_blank">Link</a>';
                  // adiciona a virgula no fim do link
                  splt = splt + '.';
                } else if (splt.split('').pop() === ',') {
                  splt = splt.split('').splice(0, splt.length - 1).join('');
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="' + location.protocol + '//' + splt + '" target="_blank">Link</a>';
                  // adiciona a virgula no fim do link
                  splt = splt + ',';
                } else {
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="' + location.protocol + '//' + splt + '" target="_blank">Link</a>';
                }
              }
              // popula a variavel com o texto, mesmo que não tenha URL
              newText.push(splt);
            });
            // retorna o novo texto com a tag HTML em volta da URL
            this[index] = newText.join(' ');
          } else if (part.includes('@')) {
            const txtSplit = part.split(' ');

            // percorre cada parte da mensagem para achar qual delas é um email
            txtSplit.forEach(splt => {
              if (splt.includes('@')) {

                if (splt.split('').pop() === '.') {
                  splt = splt.split('').splice(0, splt.length - 1).join('');
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="mailto:' + splt + '" target="_top">' + splt + '</a>';
                  // adiciona a virgula no fim do link
                  splt = splt + '.';
                } else if (splt.split('').pop() === ',') {
                  splt = splt.split('').splice(0, splt.length - 1).join('');
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="mailto:' + splt + '" target="_top">' + splt + '</a>';
                  // adiciona a virgula no fim do link
                  splt = splt + ',';
                } else {
                  // insere uma tag HTML ao redor do link para torná-lo clicável
                  splt = '<a class="webchat__red-link" href="mailto:' + splt + '" target="_top">' + splt + '</a>';
                }
              }
              // popula a variavel com o texto, mesmo que não tenha email
              newText.push(splt);
            });
            // retorna o novo texto com a tag HTML em volta do email
            this[index] = newText.join(' ');
          }
        }, msg.text.text);
      }
    });

    this.messages.next(this.messages.getValue().concat(message));
  }


  // faz o tratamento da query primeiro antes de enviar para o Dialogflow
  prepareQuery(requestType, requestText, projectId, showUserMessage = true) {
    let body;
    // se requestType = 1, envia mensagem
    const queryRequest = {
          text: {
              text: requestText,
              languageCode: 'pt-BR',
        },
    };
    // se requestType = 2, envia evento
    const eventRequest = {
        event: {
          name: requestText,
          languageCode: 'pt-BR'
      },
    };

    if (requestType === 1) {
      body = {
        queryInput: queryRequest,
        sessionId: this.sessionId,
        projectId: projectId,
      };
      if (showUserMessage) {
        this.update([{sentBy: 'user', text: requestText}]);
      }
    } else if (requestType === 2) {
      body = {
        queryInput: eventRequest,
        sessionId: this.sessionId,
        projectId: projectId,
      };
    }
    return body;
  }

  // envia mensagem para o bot e recebe um JSON de resposta
  async sendQuery(requestType, requestText, chatLink, projectId, showUserMessage = true) {
    const body = this.prepareQuery(requestType, requestText, projectId, showUserMessage);
    return new Promise(resolve => {
      this.http.post(chatLink, body).subscribe((res) => {
          this.organizeMessages(res);
          resolve(res);
        });
    });
  }

  organizeMessages(messages) {
    let fulfillmentMessages = []; // array de mensagens a ser retornado
    const platforms         = []; // array com todas as plafatormas da resposta obtida
    const cards             = []; // array com todos os cards da resposta obtida

    // filtra as plataformas das mensagens
    messages.fulfillmentMessages.forEach(element => {
      platforms.push(Object.values(element)[0]);
    });

    messages.fulfillmentMessages.forEach(element => {
      if (messages.fulfillmentMessages.length > 1) {
        // se tiver mensagens pra Skype, usa elas
        if (platforms.includes('SKYPE')) {
          if (element.platform === 'SKYPE') {
            element.sentBy    = 'bot';
            element.timestamp = messages.timestamp;
            fulfillmentMessages.push(element);
          }
          // se não tiver Skype, mas tiver Facebook, usa elas
        } else if (!platforms.includes('SKYPE') && platforms.includes('FACEBOOK')) {
          if (element.platform === 'FACEBOOK') {
            element.sentBy    = 'bot';
            element.timestamp = messages.timestamp;
            fulfillmentMessages.push(element);
          }
          // se nao tiver nenhum dos dois, então é mensagem normal
        } else if (!platforms.includes('SKYPE') && !platforms.includes('FACEBOOK')
                  && platforms.includes('PLATFORM_UNSPECIFIED')) {
          if (element.platform === 'PLATFORM_UNSPECIFIED') {
            element.sentBy    = 'bot';
            element.timestamp = messages.timestamp;
            fulfillmentMessages.push(element);
          }
        }
      } else {
        element.sentBy    = 'bot';
        element.timestamp = messages.timestamp;
        fulfillmentMessages.push(element);
      }
    });
    if (messages.action) {
      const element   = { action: '' };
      element.action  = messages.action;
      fulfillmentMessages.push(element);
    }
    // filtra todos os cards das mensagens
    // quando encontra joga ele no array cards
    // e exclui o elemento do array original
    fulfillmentMessages.filter(msgs => {
      if (msgs.message === 'card') {
        cards.push(msgs);
        fulfillmentMessages = fulfillmentMessages.filter(msg => msg !== msgs);
      }
    });
    // se algum card tiver sido filtrado
    // joga o array cards para o array original de mensagens
    if (cards.length) {
      fulfillmentMessages.push({ message: 'card', cardList : cards });
    }

    // atualiza o objeto observable de mensagens para ser captado no webchat component
    this.update(fulfillmentMessages);
  }
}
