import { Component, OnInit } from '@angular/core';
import { IntentService } from 'src/app/common/services/intent.service';
import { CredentialService } from 'src/app/common/services/credential.service';
import { ConfigService } from 'src/app/common/services/config.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent implements OnInit {

  userData: any;
  currentAgent: any = false;
  paramsFilter:  any;
  projectId: any;
  location: any;
  isLoaded = false;
  userEmail: any;
  selectedGraph: string = ''; 
  graphs;

  constructor(
    private http: HttpClient,
    private credentialService: CredentialService,
    private configService: ConfigService
    ) {
      this.currentAgent = configService.currentAgentData['client_name'];

  }

  ngOnInit() {
    this.graphs = this.configService.currentAgentData['chartLink'];
    this.projectId = this.configService.currentAgentData['project_id'];
    this.userEmail = this.configService.userEmail;
    this.initializeSelectedGraph();
    this.getData()
  }

  async getData(){
    let path = `/segmentacao/${this.userEmail}`;

    await this.http.get(environment.baseUrl + path).subscribe((res: any) => {
      this.userData = res;
      this.encodeFilter();
    });
  }

  encodeFilter(){
    const ALL_AREAS = "3b1871dd";
    const dict = {"ds0.project_id": this.projectId, "ds2.project_id": this.projectId,  "ds13.project_id": this.projectId, "ds30.project_id": this.projectId, "ds13.area_funcionario": ALL_AREAS};

    if (this.userData.location && this.userData.location[0] !== 'all' && this.projectId === 'hortifruti-atestados-hpcu') {
      dict["ds13.area_funcionario"] = this.userData.location;
    }
    
    const stringify = JSON.stringify(dict);
    const encode = encodeURIComponent(stringify)
    this.paramsFilter = `?params=` + encode
    return this.paramsFilter;
  }

  initializeSelectedGraph() {
    if (this.graphs && this.graphs.length > 0) {
      this.selectedGraph = this.graphs[0];
    }
  }

}
