import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(timestamp): any {
    return new Date(timestamp).toLocaleDateString('pt-BR',
    {timeZone: 'UTC'});
  }

}


@Pipe({
  name: 'formatFullDate'
})
export class FormatFullDatePipe implements PipeTransform {

  transform(timestamp): any {
    return new Date(timestamp).toLocaleString("pt-BR",
    {timeZone: "America/Sao_Paulo"});
  }

}


@Pipe({
  name: 'formatDateBrazil'
})
export class FormatDateBrazilPipe implements PipeTransform {

  transform(timestamp): any {
    const formattedDate = new Date(timestamp)
    formattedDate.setHours(formattedDate.getHours() - 3)
    return formattedDate.toLocaleString("pt-BR",
    {timeZone: "America/Sao_Paulo"});
  }

}

@Pipe({
  name: 'formatFullDateUTC'
})
export class FormatFullDatePipeUTC implements PipeTransform {

  transform(timestamp): any {
    return new Date(timestamp).toLocaleString("pt-BR",
    {timeZone: "UTC"});
  }

}
