import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-buttons',
  templateUrl: './table-buttons.component.html',
  styleUrls: ['./table-buttons.component.scss']
})
export class TableButtonsComponent implements OnInit {

  @Input() color: 'primary' | 'accent' = 'primary';
  @Input() disabled: boolean = false;
  @Input() buttonSize: false;

  constructor() { }

  ngOnInit() {
    if (this.color != 'primary' && this.color != 'accent') {
      throw 'color input field must be either "primary" or "accent"';
    }
  }

  onClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

}
