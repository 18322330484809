import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CredentialService } from '../common/services/credential.service';
import { ConfigService } from '../common/services/config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isAdmin: boolean;
  currentAgent: any;
  showMenu: boolean = Boolean(window.self === window.top);

  constructor(
    private titleService: Title,
    private credentialService: CredentialService,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Dashboard | Bondy');
    this.isAdmin = this.credentialService.isAdmin;
    this.currentAgent = this.configService.currentAgentData['client_name'];
    this.getParams();
  }

  getParams() {
    const searchParams = new URLSearchParams(window.location.href.split("?")[1])
    const getValue = searchParams.get('showMenu')
    if ( getValue ) {
      this.showMenu = (window.self === window.top) || Boolean(Number(getValue))
      return this.showMenu
    }
  }

}
