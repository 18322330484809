import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()

export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private configService: ConfigService,
        private toastr: ToastrService,
        ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.configService.accessToken;
        const refreshToken = this.configService.refreshToken;

        // Add the refresh token to the requisition body when is near expiration time.
        if (this.configService.isTokenCloseToExpire && request.body) {
          request.body.refreshToken = refreshToken;
        }
        if (token && request.url.includes(environment.baseUrl)) {
          request = request.clone({
              withCredentials: true,
              setHeaders: {
                  'Authorization': `Bearer ${token}`
              },
              body: {
                ...request.body
              }
          });
        }

        return next.handle(request).pipe<any>(
            tap(event => {
                if (event instanceof HttpResponse) {
                  // Check expired session
                  if (event.body
                      && event.body.hasOwnProperty('status')
                      && event.body['status'] === 'error'
                      && typeof event.body['message'] === 'string'
                      && event.body['message'].includes('Sessão expirada')
                      ){
                        if (window.self === window.top){
                            this.toastr.error(event.body.message, 'Erro!');
                            this.configService.cleanData();
                            this.router.navigate(['/login']);
                        }
                   }
                  // Get new access token and update the expiration time.
                  if (event.headers.get('X-Access-Token')) {
                    this.configService.accessToken = event.headers.get('X-Access-Token');
                    this.configService.setNewExpirationDate();
                  }
                }

            },
            error => {
                if(error instanceof HttpResponse){
                    //api call error
                    console.log('error in calling API : ', error);
                }

                if ([401, 403].includes(error.status)) {
                    if (window.self === window.top) {
                        this.toastr.error('Acesso não autorizado.', 'Erro!');
                        this.configService.cleanData();
                        this.router.navigate(['/dashboard']);
                    }
                }
            }
            ));
    }
}


export const authInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptor,
        multi: true,
    }
];
