import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  @Input() labels: string[] = [];
  @Input('selected') selectedIndex = 0;
  @Output() changed = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    if (this.labels.length < 1) {
      throw 'At least one label is required';
    }

    this.emitChange(this.selectedIndex);
  }

  emitChange(index) {
    this.selectedIndex = index;
    this.changed.emit(index);
  }

}
