import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AESEncryptDecryptServiceService } from '../services/aesencrypt-decrypt-service.service';
import { ConfigService } from '../services/config.service';
import { CredentialService } from '../services/credential.service';


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  currentEmail: any;
  isAdmin: any = 'empty';

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private configService: ConfigService,
    private credentialService: CredentialService,
    private decrypt: AESEncryptDecryptServiceService) {
    this.currentEmail = this.configService.userEmail || 'empty';
    this.isAdmin = this.credentialService.isAdmin;
  }

  canActivate(): boolean {
    let token = this.configService.accessToken;
    if (!token) {
      this.configService.cleanData();
    }
    let mfa = this.cookieService.get('hrBotMFA');
    if (this.isAdmin === false && this.currentEmail.includes('@coty')) {
      mfa = this.decrypt.decrypt(mfa);
      if (mfa && token) {
        return true;
      }
      this.router.navigate(['/login']);
      return false;
    }
    if (token) {
      return true;
    }
    if ( window.top != window.self){
      const msg = {
        type: 'errorOnGuard',
        message: 'Error no authGuard'
      }
  
      window.parent.postMessage(msg, '*')

    }
    this.router.navigate(['/login']);
    return false;

  }
}


@Injectable({
  providedIn: 'root',
})
export class MFAGuard implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private configService: ConfigService,
    ) {}

  canActivate(): boolean {
    let token = this.configService.accessToken;
    const mfa = this.cookieService.get('hrBotMFA');
    if (!token) {
      this.router.navigate(['/login']);
      return true;
    }
    if (!mfa) {
      return true;
    }
    this.router.navigate(['/dashboard']);
    return false;
  }
}
