import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  issueData = {}


  constructor(private http: HttpClient) { }

  setData(data: any) {
    this.issueData = data;
  }

  getData(): any {
    return this.issueData;
  }

  sortByDate(array) {
    return array.sort((a, b) => {
      const keyA = new Date(a.timestamp);
      const keyB = new Date(b.timestamp);
      // Compare the 2 dates
      if (keyA > keyB) {
        return -1;
      } else
      if (keyA < keyB) {
        return 1;
      }
      return 0;
    });
  }


  getSheetData(projectId: string, email: string, filter=undefined) {
    const body = {
      project_id: projectId,
      email: email,
      filter: filter,
    }

    return new Promise<any>(resolve => {
      this.http.post(`${environment.baseUrl}/chamados/find`, body).subscribe((res) => {
        resolve(res);
      });
    });
  }


  getCategory(projectId) {
    return new Promise<any>(resolve => {
      this.http.get(`${environment.baseUrl}/chamados/categorias?project_id=${projectId}`).subscribe((res) => {
        resolve(res);
      });
    });
  }


  getIssue(issueId) {
    return new Promise<any>(resolve => {
      this.http.get(`${environment.baseUrl}/chamados/${issueId}`).subscribe((res) => {
        resolve(res);
      });
    });
  }


  getIssuePublic(issueId) {
    return new Promise<any>(resolve => {
      this.http.get(`${environment.baseUrl}/issues/${issueId}`).subscribe((res) => {
        resolve(res);
      });
    });
  }


  getChatData(issueId) {
    return new Promise<any>(resolve => {
      this.http.get(`${environment.baseUrl}/respostas-chamados/${issueId}`).subscribe((res) => {
        resolve(res);
      });
    });
  }


  async readData(projectId, email, filter=undefined) {
    let data_items = await this.getSheetData(projectId, email, filter);

    let data = {
      itemsCount: data_items.itemsCount,
      data: this.sortByDate(data_items.data),
    }

    // convert os timestamps em data legível
    for await (const el of data.data) {
      // tslint:disable: no-string-literal
      el['answer_date'] = new Date(el['answer_date']).toLocaleString('pt-BR',
                                                    {timeZone: 'UTC'});

      if (el['log_history']) {
        el['log_history'] = JSON.parse(el['log_history']);

        el['log_history'].log.sort((a, b) => {
          const keyA = new Date(a.timestamp);
          const keyB = new Date(b.timestamp);
          // Compare the 2 dates
          if (keyA < keyB) {
            return -1;
          }
          if (keyA > keyB) {
            return 1;
          }
          return 0;
        });
      }
    }
    return data;
  }


  /**
   * Creates a new register in `respostas-chamado` collection with the new interaction in the issue.
   *
   * @return `Promise`
   */
  sendResponse(body: any) {
    return new Promise(resolve => {
      this.http.post(`${environment.baseUrl}/respostas-chamados/`, body).subscribe((res) => {
          resolve(res);
        });
    });
  }


  /**
   * Updates the issue with the values from body
   * @return Promise
   */
  updateIssue(issueId: string, body: any) {
    return new Promise(resolve => {
      this.http.put(`${environment.baseUrl}/chamados/${issueId}`, body).subscribe((res) => {
          resolve(res);
        });
    });
  }


  /**
   * Get the solved status of the issue
   *
   * @return boolean Promise
   */
   checkIssueSolvedStatus(sessionId: string) {
    return new Promise(resolve => {
      this.http.get(`${environment.baseUrl}/chamados/check-status/${sessionId}`).subscribe((res) => {
          resolve(res);
        });
    });
  }


  /**
   * Delete the issue by its ID
   *
   * @return boolean Promise
   */
  deleteIssue(id: string) {
    return new Promise(resolve => {
      this.http.delete(`${environment.baseUrl}/chamados/${id}`).subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Formats the data to be compatible with the JSON format
   *
   * @return `data object`
   */
  formatData(data) {
    // remove os elementos da string que possam atrapalhar no JSON
    data = data.replace(/"/g, '');
    data = data.replace(/'/g, '');
    data = data.replace(/{/g, '');
    data = data.replace(/}/g, '');
    return data;
  }

  /**
   * Formats the date to Brazil timezone
   *
   * @return `Date()`
   */
  dateTimestamp() {
    const utcDate = new Date().toUTCString();
    const newDate = new Date(utcDate);
    const hour = newDate.getHours();
    const min = newDate.getMinutes();
    const sec = newDate.getSeconds();

    const newDateTime = newDate.setHours(hour - 3, min, sec);

    const formatedDate = new Date(newDateTime);

    return formatedDate;
  }


  /**
   * Sends an email with the issue reply
   * @param data dict with the issue data
   * @returns Promise
   */
  async sendSheetReplyEmail(data) {
    const answerDate = this.dateTimestamp().toLocaleString('pt-BR', {timeZone: 'UTC'});
    let email_layout = "chamado_email_respondido.html";
    let footer_email =  "Atenciosamente, Spark Bondy | https://sparkrh.com/sparkhrbot"
    let imgSrc;

    if ( data.project_id == "coty-fnqr" ) {
      email_layout = 'chamado_email_respondido_coty.html'
    }
    else if ( data.project_id == "cddxp-qtty" ) {
      email_layout = "chamado_email_respondido_cddxp.html"
    }
    else if ( data.project_id == "sankhya-tpqw" ) {
      email_layout = "chamado_email_respondido_sankhya.html"
    }
    else if ( data.project_id == "boavista-necbj") {
      email_layout = "chamado_email_respondido_boaVista.html"
      imgSrc = 'https://storage.googleapis.com/hrbot-files/images/boaVista-circle-avatar2.png';
    }

    if (data.answer) {
      data.answer = data.answer.replace(/\n/g, "<br>");
    }

    //from, to, subject, text, cc, emailTemplate, replacements, attachments
    const body = {
      from: 'Bondy Chamados',
      replyTo: `no-reply+id${data.id}@hrbot.com.br`,
      to: data.user_contact,
      subject: 'Chamado Atualizado',
      text: `Seu chamado foi atualizado!\
        Seguem os dados do chamado.\
        ID: ${data.id}\
        Problema: ${data.issue}\
        Resposta: ${data.answer}\
        Data da resposta do chamado: ${data.answer_date}\
        Atenciosamente,\
        Bondy\
      `,
      cc: data.cc ? data.cc : '',
      emailTemplate: email_layout,
      replacements: {
          id: data.id,
          issue: data.issue,
          timestamp: data.timestamp,
          answer: data.answer,
          answer_date: answerDate,
          token: data.token,
          tipo_programa: data.tipo_programa,
          nome_programa: data.nome_programa,
          footer_email: footer_email,
          imgSrc: imgSrc
      },
      attachments: data.fileLink ? [
        {
          filename: data.filename,
          path: data.fileLink,
        }
      ] : null
    }
    return await this.sendEmail(body);
  }



  async sendNewQuestionEmail(data) {
    let email_layout = 'chamado_email_nova_pergunta.html';
    let footer_email =  "Atenciosamente, Spark Bondy | https://sparkrh.com/sparkhrbot"
    let imgSrc;

    if ( data.project_id == "coty-fnqr" ) {
      email_layout = 'chamado_email_nova_pergunta_coty.html'
    }
    else if ( data.project_id == "cddxp-qtty" ) {
      email_layout = "chamado_email_nova_pergunta_cddxp.html"
    }
    else if ( data.project_id == "sankhya-tpqw" ) {
      email_layout = "chamado_email_nova_pergunta_sankhya.html"
    }
    else if ( data.project_id == "boavista-necbj") {
      email_layout = "chamado_email_nova_pergunta_boaVista.html"
      imgSrc = 'https://storage.googleapis.com/hrbot-files/images/boaVista-circle-avatar2.png';
    }

    if (data.answer) {
      data.answer = data.answer.replace(/\n/g, "<br>");
    }

    //from, to, subject, text, cc, emailTemplate, replacements, attachments
    const body = {
      from: 'Bondy Chamados',
      replyTo: `no-reply@hrbot.com.br`,
      to: 'iury@hrbot.com.br', // TODO: dynamic e-mail through Strapi
      subject: `Nova Pergunta no Chamado de ID ${data.id}`,
      text: `O usuário enviou uma nova pergunta!\
        Seguem os dados do chamado.\
        ID: ${data.id}\
        Problema: ${data.issue}\
        Atenciosamente,\
        Bondy\
      `,
      cc: data.cc ? data.cc : '',
      emailTemplate: email_layout,
      replacements: {
          id: data.issue_id,
          issue: data.issue,
          answer: data.answer,
          answer_date: data.answer_date,
          tipo_programa: data.tipo_programa,
          nome_programa: data.nome_programa,
          footer_email: footer_email,
          imgSrc: imgSrc
      },
      attachments: data.fileLink ? [
        {
          filename: data.filename,
          path: data.fileLink,
        }
      ] : null
    }
    return await this.sendEmail(body);
  }


  /**
   * Sends an e-mail when the issue is closed by the staff.
   * @param data dict containing the issue's data
   * @returns Promise
   */
  async sendSolvedIssue(data) {
    
    let email_layout = 'chamado_email_resolvido.html';
    let footer_email =  "Atenciosamente, Spark Bondy | https://sparkrh.com/sparkhrbot"
    let imgSrc;

    if ( data.project_id == "coty-fnqr" ) {
      email_layout = 'chamado_email_resolvido_coty.html'
    }
    else if ( data.project_id == "cddxp-qtty" ) {
      email_layout = "chamado_email_resolvido_cddxp.html"
    }
    else if ( data.project_id == "sankhya-tpqw" ) {
      email_layout = "chamado_email_resolvido_sankhya.html"
    }
    else if ( data.project_id == "boavista-necbj" ) {
      email_layout = "chamado_email_resolvido_boaVista.html"
      imgSrc = 'https://storage.googleapis.com/hrbot-files/images/boaVista-circle-avatar2.png';
    }
    
    if (data.answer) {
      data.answer = data.answer.replace(/\n/g, "<br>");
    }
    if (data.timestamp) {
      data.timestamp = this.dateTimestamp().toLocaleString('pt-BR', {timeZone: 'UTC'});
    }

    //from, to, subject, text, cc, emailTemplate, replacements, attachments
    const body = {
      from: 'Bondy Chamados',
      replyTo: `no-reply@hrbot.com.br`,
      to: data.user_contact,
      subject: `Chamado de ID ${data.id} Resolvido!`,
      text: `Seu chamado foi marcado como resolvido!\
              Seguem os dados do chamado.\
              Problema: ${data.issue}\
              Data de encerramento: ${data.timestamp}\
              Atenciosamente,\
              Bondy\
            `,
      cc: data.cc ? data.cc : '',
      emailTemplate: email_layout,
      replacements: {
          id: data.id,
          issue: data.issue,
          timestamp: data.timestamp,
          token: data.token,
          tipo_programa: data.tipo_programa,
          nome_programa: data.nome_programa,
          footer_email: footer_email,
          imgSrc: imgSrc
      },
      attachments: data.fileLink ? [
        {
          filename: data.filename,
          path: data.fileLink,
        }
      ] : null
    }
    return await this.sendEmail(body);
  }


  sendEmail(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.baseUrl}/send-mail`, data).subscribe((res) => {
          if (res.hasOwnProperty('status') && res['status'] === 'error') {
            reject(res);
          }
          resolve(res);
        });
    });
  }


  /**
   * (UNUSED AS OF NOW) Send a template message to WhatsApp with the issue response
   * @param data
   * @return Promise
   */
  // sendToWhatsApp(data) {
  //   const issueId = data.id;
  //   const response = data.answer;
  //   const phoneNumber = data.user_contact;

  //   const body = {
  //     answer: response,
  //     id: issueId,
  //     phone_number: phoneNumber,
  //   };

  //   return new Promise(resolve => {
  //     this.http.post(environment.baseUrl + '/chamados/whatsapp-template', { body }, {responseType: 'text'}).subscribe((res) => {
  //         resolve(res);
  //       });
  //   });
  // }

  uploadFile(file): Promise<any> {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.onreadystatechange = () => {
        if(req.readyState === 4){
          if (req.status === 200){
            resolve(JSON.parse(req.response))
          }
          else{
            reject(req.response)
          }
        }
      }
      
      req.open("POST", `${environment.baseUrl}/upload`);
      req.send(file);
    })
  }

  uploadImage(file: File) {
    let result
    const formData = new FormData();
    formData.append('files', file);
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
            if (req.status === 200) {
                resolve(JSON.parse(req.response)[0].url)
                result = req.response[0].url
            } else {
                reject(req.response)
            }
        }
      }
      req.open("POST", `${environment.baseUrl}/upload`)
      req.send(formData);
      return result
    });
  }

  async addNewIssue(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.baseUrl}/chamados`, data).subscribe((res) => {
        resolve(res);
      }, (error) => {
        reject(error);
      });
    });
  }
}
