import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../common/services/config.service';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';
import { DialogflowApiService } from './dialogflow-api.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @Input() chatLink: any;
  messages: any;      // array de mensagens que vai ser renderizado no html
  // agente atual
  currentAgent: any = '';
  projectId: any = '';

  constructor(
    private dialogflowAPI: DialogflowApiService,
    private gaService: GoogleAnalyticsService,
    private configService: ConfigService
    ) { }

  ngOnInit() {
    this.dialogflowAPI.messages.subscribe(data => this.messages = data);
    this.currentAgent = this.configService.currentAgentData['client_name'];
    this.projectId = this.configService.projectId;
    this.cleanMessages();
  }

  async sendQuery(userQuery, sendType = 1, chatLink = this.chatLink) {
    this.gaService.eventEmitter('send-message', 'chatbot', 'click-button', this.currentAgent, 1);
    // sendType indica se o usuário está enviando a mensagem pelo input ou botao
    // caso seja por input, não abre nova aba com o que for link

    // tslint:disable: no-string-literal
    document.getElementById('user-query')['value'] = ''; // apaga o campo de texto
    document.getElementById('user-query').focus(); // refoca o input mesmo se o usuario clicar em um botao
    if (userQuery) {
      if ((userQuery.includes('http') || userQuery.includes('www')) && sendType === 1) {
        if (!userQuery.includes('http')) {
          window.open('https://' + userQuery.split('www.')[1], '__blank');
          return;
        }
        window.open(userQuery, '__blank');
        return;
      }
      return await this.dialogflowAPI.sendQuery(1, userQuery, chatLink, this.projectId);
    }
  }

  // funcao de mover o carrossel
  moveSlider(direction, target) {
    // pega o estado atual do css 'transform' que vem como translateX(pixels) ou translateY(pixels)
    // da split entre os parenteses para pegar apenas os pixels (ex.: resultado será 50px)
    // e transforma isso em numero, esse 10 no final é para indicar que o numero é um decimal
    let movement    = target.style.transform ? parseInt(target.style.transform.split('(')[1].split(')')[0], 10) : 0;
    const cardWidth = target.children[0].offsetWidth; // a largura do primeiro card dentro do carrossel

    if (direction === 'right') {
      const targetRight       = target.parentNode.children[2].getBoundingClientRect().right;
      const lastChildPosition = target.lastChild.getBoundingClientRect().right;
      const diff              = parseInt(lastChildPosition, 10) - parseInt(targetRight, 10);
      // se a diferença entre a distancia do final (right) do ultimo filho e o final da seta da direita for menor
      // que a largura do card, que é o movimento, move o valor da diferença
      if (diff <= cardWidth) {
        movement -= diff;
        // 'translateX' vai mover o carrossel pelo eixo X, ou seja, na horizontal
        target.style.transform = 'translateX(' + movement + 'px)';
        return;
      }
      movement -= cardWidth; //  a largura do card
      target.style.transform = 'translateX(' + movement + 'px)';
    } else {
      const targetLeft          = target.parentNode.children[1].getBoundingClientRect().left;
      const firstChildPosition  = target.children[0].getBoundingClientRect().left;
      const diff                = parseInt(firstChildPosition, 10) - parseInt(targetLeft, 10);
      // se a diferença entre a distancia do inicio (left) do primeiro filho e o inicio da seta da esquerda for maior
      // que a largura do card na negativa, que é o movimento, move o valor da diferença
      if (diff >= -cardWidth) {
        movement -= diff;
        // 'translateX' vai mover o carrossel pelo eixo X, ou seja, na horizontal
        target.style.transform = 'translateX(' + movement + 'px)';
        return;
      }
      movement += cardWidth; // a largura do card
      target.style.transform = 'translateX(' + movement + 'px)';
    }
  }

  cleanMessages() {
    this.dialogflowAPI.messages.next([]);
  }
}
