import { Component, OnInit } from '@angular/core';
import { ConfigService } from './common/services/config.service';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
import { Mixpanel } from './common/services/mixpanel.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  allowedOrigins = [
    "http://localhost:3000",
    "https://app.sparkrh.com",
    "https://app.dev.sparkrh.com",
    "https://app.bondy.com.br",
    "https://app.dev.bondy.com.br",
    "https://next-spark.vercel.app",
    "https://app.bondy.com",
    "https://app.dev.bondy.com.br",
    /http?s:\/\/next-spark-.*-bondy.vercel.app/g
  ]

  constructor(
    private gaService: GoogleAnalyticsService,
    private configService: ConfigService,
    private mixpanel: Mixpanel,
    private cookieService: CookieService,
    private router: Router,
  ) {
    this.gaService.init();
  }

  ngOnInit() {
    if (this.configService.currentUser) {
      const userIdentifyMixpanel = {
        email: this.configService.currentUser['email'],
        company: this.configService.currentAgentData['client_name'],
        name: `${this.configService.currentUser['firstName']}_${this.configService.currentUser['lastName']}`,
      };

      this.mixpanel.userIdentify(userIdentifyMixpanel);
    }
    window.addEventListener('message', this.receiveMessage.bind(this));
  }


  handlerMessage = {
    assistentToken : ({data}) => {
      const currToken = this.cookieService.get('hrBotToken')
      const currRefresh = this.cookieService.get('hrBotRT')
      const currExp = this.cookieService.get('hrExp')
      const currData = localStorage.getItem('hrBotData')

      if(currToken !== data.HrAccessToken)
        this.cookieService.set('hrBotToken', data.HrAccessToken, null, '/', null, true, 'None'); 
      
      if(currRefresh !== data.HrRefreshToken)
        this.cookieService.set('hrBotRT', data.HrRefreshToken, null, '/', null, true, 'None');

      if(currExp !== data.HrExpirationTime)
        this.cookieService.set('hrExp', data.HrExpirationTime, null, '/', null, true, 'None');

      if (currData != data.hrBotData) {
        localStorage.setItem('hrBotData', data.hrBotData);
      }

      if(this.configService && this.configService.currentUser) {
        this.router.navigate([`/${data.assistentPath}`])
        const msg = {
          type: 'assistentData',
          data: {
            currToken: this.cookieService.get('hrBotToken'),
            currRefresh: this.cookieService.get('hrBotRT'),
            currExp: this.cookieService.get('hrExp'),
            currData: localStorage.getItem('hrBotData'),
            user: this.configService.currentUser
          }
        }
        setTimeout(() => {
          window.parent.postMessage(msg, '*')
        }, 100)
      }
    }
  }

  isallowedOrigin = (origin) => {
    return this.allowedOrigins.some(_allowedOrigin => {
      return typeof _allowedOrigin === 'string' ? _allowedOrigin.includes(origin) : _allowedOrigin.test(origin)
    })
  }

  receiveMessage(event: MessageEvent) {
    if (!this.isallowedOrigin(`${event.origin}`)) return
    if (typeof event.data !== 'object') return
    if (!event.data.type) return
    if (this.handlerMessage[event.data.type]) this.handlerMessage[event.data.type](event.data)
  }
}
