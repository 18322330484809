import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/common/services/config.service';
import { IssuesRatingService } from 'src/app/issues-rating/issues-rating.service';
import { IssuesService } from 'src/app/issues/issues.service';
import { Chat } from '../../interfaces/chat';

@Component({
  selector: 'app-chat3',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatUserComponent implements OnInit {

  @Input() chats: Chat[] = [];
  @Input() issueSolved: boolean = true;
  @Input() sendAnswer: any;
  @Input() issueData: any;
  @Input() issueId: string;
  currentEmail: string = '';
  isLoaded = false;

  constructor(
    private issueService: IssuesService,
    private toastr: ToastrService,
    private configService: ConfigService,
    private issuesRatingService: IssuesRatingService,
    ) {
      this.currentEmail = this.configService.userEmail;
     }


  sendMessage(text) {
    const answer = text.value.trim();
    // impedir que uma resposta nula ou muito pequena seja aceita
    if (!answer || answer.length < 2) return;
    // limpa o campo de texto
    text.value  = '';
    this.chats.push({
      from: 'user',
      message: answer,
      time: new Date(),
      email: this.issueData.user_contact,
    });
    // grava a resposta no banco de dados e envia um email para o usuario
    this.sendAnswer(answer, this.issueData, this.issueId);
  }

  ngOnInit() {
    this.isLoaded = true;
  }

}
