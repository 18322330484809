import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DialogflowApiService } from '../chat/dialogflow-api.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../common/services/config.service';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';

@Component({
  selector: 'app-web-chat',
  templateUrl: './web-chat.component.html',
  styleUrls: ['./web-chat.component.scss']
})
export class WebChatComponent implements OnInit {

  @Input() fixed: boolean = true;

  currentAgent: any = false;
  chatOpened = false;
  webchatLink: any = 'https://us-central1-hrbot2.cloudfunctions.net/dialogflow-api';
  showChatButton = true;
  projects: any;
  projectId: any;

  constructor(

    private router: Router, private dialogflowAPI: DialogflowApiService,
    private toast: ToastrService, private configService: ConfigService,
    private gaService: GoogleAnalyticsService,
    ) { }

  ngOnInit() {
    this.currentAgent = this.configService.currentAgentData;
    this.projectId = this.configService.projectId;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url !== '/intents'
          && this.router.url !== '/dashboard') {
            this.showChatButton = false;
          } else {
            this.showChatButton = true;
          }
      }
    });
  }

  toggleChat() {
    this.gaService.eventEmitter('toggle-chat', 'chatbot', 'click-button', this.currentAgent['client_name'], 1);
    if (this.chatOpened) {
      this.chatOpened = false;
      this.webchatLink = '';
      return;
    }
    this.webchatLink = this.currentAgent.webchatLink ? this.currentAgent.webchatLink : this.webchatLink;
    if (this.webchatLink) {
      // envia evento de welcome para iniciar a conversa
      this.dialogflowAPI.sendQuery(2, 'Welcome', this.webchatLink, this.projectId);
      this.chatOpened = true;
    } else {
      this.toast.error('Erro ao abrir o chat!', 'Erro!');
    }
  }

  cleanData() {
    this.currentAgent = false;
  }
}
