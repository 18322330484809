import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CredentialService {

constructor(
  private configService: ConfigService,
  ) { }

  get isAdmin() {
    let result = this.configService.currentUser;
    let currentAgent = this.configService.currentAgentData['client_name'];
    if (
      result
      && result.hasOwnProperty('profile')
      && result['profile'].name.toLowerCase().includes('admin')
      && currentAgent.toLowerCase().includes('admin')
      ) return true;
    return false;
  }

}
