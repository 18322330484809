import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatModule } from './chat/chat.module';
import { authInterceptorProviders } from './common/interceptor/Request.interceptor';
import { AESEncryptDecryptServiceService } from './common/services/aesencrypt-decrypt-service.service';
import { AgentService } from './common/services/agent.service';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
import { UserService } from './common/services/user.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { QuillModule } from 'ngx-quill';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    DashboardModule,
    ChatModule,
    SharedModule,
    SocialLoginModule,
    QuillModule.forRoot()
  ],
  providers: [
    AgentService,
    UserService,
    CookieService,
    AESEncryptDecryptServiceService,
    authInterceptorProviders,
    GoogleAnalyticsService,
    {provide: 'googleTagManagerId', useValue: 'GTM-5MVXCNT'},
    // AuthGuard,
    // RedirectGuard
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '241290318479-tenmf27p84b5fofnvaigeegivhm8jauq.apps.googleusercontent.com'
            )
          },
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
