import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

  @Input() title = '';
  @Input() primaryActionLabel = '';
  @Input() expanded: boolean = true;
  @Output() primaryActionClicked = new EventEmitter();

  constructor() { }
  
  ngOnInit() {
  }

  primaryAction() { 
    this.primaryActionClicked.emit();
  }

}
