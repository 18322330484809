import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from '../common/pipes/safe.pipe';
import { FormatDateBrazilPipe, FormatDatePipe, FormatFullDatePipe, FormatFullDatePipeUTC } from '../common/pipes/format-date.pipe';
import { LoadingComponent } from '../loading/loading.component';
import { MaterialModule } from '../material.module';
import { FormFieldComponent } from './components/form/form-field/form-field.component';
import { TableButtonsComponent } from './components/table-buttons/table-buttons.component';
import { ChatComponent } from './components/chat-bubble/chat.component';
import { ChatUserComponent } from './components/chat-bubble-user/chat.component';
import { Card1Component } from './components/card1/card1.component';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog/confirmation-dialog.component';
import { TabComponent } from './components/tab/tab.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    SafePipe,
    FormatDatePipe,
    FormatFullDatePipe,
    FormatFullDatePipeUTC,
    FormatDateBrazilPipe,
    LoadingComponent,
    FormFieldComponent,
    TableButtonsComponent,
    ChatComponent,
    ChatUserComponent,
    Card1Component,
    ConfirmationDialogComponent,
    TabComponent,
    ExpansionPanelComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    FormsModule,
  ],
  exports: [
    SafePipe,
    FormatDatePipe,
    FormatFullDatePipe,
    FormatFullDatePipeUTC,
    FormatDateBrazilPipe,
    LoadingComponent,
    MaterialModule,
    FormFieldComponent,
    TableButtonsComponent,
    ChatComponent,
    ChatUserComponent,
    Card1Component,
    ConfirmationDialogComponent,
    TabComponent,
    ExpansionPanelComponent,
    ReactiveFormsModule,
    MatPaginatorModule,
  ],
  entryComponents: [
    ConfirmationDialogComponent, // todo:: remove this after update to angular v10
  ]
})
export class SharedModule { }
