import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IssuesRatingService {

  alphabet = {
    0: 'dy29',
    1: 'x2hu',
    2: 'utrw',
    3: 'mp1h',
    4: '0dom',
    5: '5c5b',
    6: 'a76k',
    7: 'fe8s',
    8: 'g1ca',
    9: '2uax',
  }

  constructor() { }

  /** Simple encrypt algorithm */
  numberToString(number) {
    // Split out each digit of the number:
    const digits = Math.floor(number).toString().split("").map(Number);

    // Then create a new string using the alphabet:
    // example: 123 => x2hu-utrw-mp1h
    return digits.map(digit => this.alphabet[digit]).join('-');
  }

  /** Simple decrypt algorithm */
  stringToNumber(string) {
    const digits = string.split('-').map(digit => this.getKeyByValue(this.alphabet, digit)).join('');
    return digits;
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }
}
