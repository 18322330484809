import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Mixpanel {
  private static _instance: Mixpanel;

  public static getInstance(): Mixpanel {
    if (Mixpanel._instance == null) {
      return (Mixpanel._instance = new Mixpanel());
    }
    return this._instance;
  }

  public constructor() {
    if (Mixpanel._instance) {
      throw new Error(
        'Error: Instance creation of Mixpanel is not allowed. Use Mixpanel.getInstance instead',
      );
    }

    mixpanel.init('6d0d9fa051c4f417a00bd22619ef9727', {
      debug: true,
      ignore_dnt: true,
    });
  }

  public track(name: string, data: object = {}) {
    mixpanel.track(name, data);
  }

  public pageViewed() {
    this.track('page_view');
  }

  public userIdentify({ email, ...props }) {
    mixpanel.identify(email);
    mixpanel.register({
      company: props.company,
      name: props.name,
      production: environment.production,
    });
  }
}
