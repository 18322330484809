import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';
import { RedirectGuard } from './common/guards/redirect.guard';

const routes: Routes = [
  {
    path: 'issue-historic',
    loadChildren: () => import('./issues-historic/issues-historic.module').then(m => m.IssuesHistoricModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'rate-issue',
    loadChildren: () => import('./issues-rating/issues-rating.module').then(m => m.IssuesRatingModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'reset-password/:id',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password-email/reset-password-email.module').then(m => m.ResetPasswordEmailModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    // canActivate: [RedirectGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./common/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];


@NgModule(
  {
  imports: [RouterModule.forRoot(routes,
    {
      useHash: true,
      scrollPositionRestoration: 'enabled'
     }
     )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
