import { Injectable } from '@angular/core';
import { AESEncryptDecryptServiceService } from './aesencrypt-decrypt-service.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

interface Agent {
  chartLink: Array<string>,
  client_name: string,
  issues: boolean,
  medical_leave: boolean,
  project_id: string,
  webchatLink: string,
  octadesk: boolean | null,
  octadesk_token: string | null,
  whatsapp_notification: boolean | null,
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private crypt: AESEncryptDecryptServiceService,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private router: Router,
    ) { }


    get currentAgentId(): number {
      const agentId = localStorage.getItem('agentId');
      if (!agentId) return 9999;
      return parseInt(agentId, 10);
    }

    set currentAgentId(id) {
      localStorage.setItem('agentId', JSON.stringify(id));
    }

  /**
   * Check how many minutes are left before token will expire
   * Return `true` if it expires in 10 minutes or less, otherwise returns `false`
   */
  get isTokenCloseToExpire(): boolean {
    const expireTime = this.cookieService.get('hrExp');
    if (!expireTime) return true;
    const expireDate = new Date(parseInt(expireTime));
    const now = new Date();
    const timeDiff = expireDate.getTime() - now.getTime();
    const minutesLeft = Math.floor(timeDiff / 1000 / 60)
    return minutesLeft <= 10;
  }

  /**
   * When the new access token is set, the expiration time is updated.
   */
  setNewExpirationDate(): void {
    const now = new Date();
    // Take one minute less than one hour to avoid errors.
    const oneHour = 59 * 60 * 1000;
    const nextHour = new Date(now.getTime() + oneHour);
    this.cookieService.set('hrExp', nextHour.getTime().toString());
  }

  /**
   * Check if the current agent has permission to see the Medical Leave Module
   * Return `true` if it has, otherwise returns `false`
   */
  get checkPermissionMedicalLeave() {
    // nome do agente atual
    let agent = localStorage.getItem('hrBotData');
    if(!agent || agent === 'undefined') return false
    agent = this.crypt.decrypt(agent);
    agent = JSON.parse(agent);
    if (!agent.hasOwnProperty('agents')) {
      this.toastr.error('Nenhum agente selecionado.', 'Erro!');
      this.cleanData();
    }
    agent = agent['agents'].filter(agent => agent.id === this.currentAgentId)[0];

    return agent && agent['medical_leave'];
  }

   /**
   * Check if the current agent has permission to see the Medical Leave Module
   * Return `true` if it has, otherwise returns `false`
   */
    get checkPermissionConfigs() {
      // nome do agente atual
      let agent = localStorage.getItem('hrBotData');
      if(!agent || agent === 'undefined') return false
      agent = this.crypt.decrypt(agent);
      agent = JSON.parse(agent);
      return agent['configs'];
    }


  /**
   * Check if the current agent has permission to see the Issues Module
   * @return `true` if it has, otherwise returns `false`
   */
   get checkPermissionIssues() {
    // nome do agente atual
    let agent = localStorage.getItem('hrBotData');
    if(!agent || agent === 'undefined') return false
    agent = this.crypt.decrypt(agent);
    agent = JSON.parse(agent);
    if (!agent.hasOwnProperty('agents')) {
      this.toastr.error('Nenhum agente selecionado.', 'Erro!');
      this.cleanData();
    }
    agent = agent['agents'].filter(agent => agent.id === this.currentAgentId)[0];

    return agent && agent['issues'];
  }


  /**
   * Check if the current agent has permission to see the Intents Page
   * @return `true` if it has, otherwise returns `false`
   */
   get checkPermissionIntents() {
    // nome do agente atual
    let data = localStorage.getItem('hrBotData');
    if(!data || data === 'undefined') return false
    data = this.crypt.decrypt(data);
    data = JSON.parse(data);
    if (!data.hasOwnProperty('agents')) {
      this.toastr.error('Nenhum agente selecionado.', 'Erro!');
      this.cleanData();
    }
    let agent = data['agents'].filter(agent => agent.id === this.currentAgentId)[0];
    let user = data['profile'];

    return agent && agent['intents'] && user && user['intents'];
  }

  /**
   * Check if the current agent has permission to see the Intents Page
   * @return `true` if it has, otherwise returns `false`
   */
   get checkPermissionUsers() {
    // nome do agente atual
    let data = localStorage.getItem('hrBotData');
    if(!data || data === 'undefined') return false
    data = this.crypt.decrypt(data);
    data = JSON.parse(data);
    if (!data.hasOwnProperty('agents')) {
      this.toastr.error('Nenhum agente selecionado.', 'Erro!');
      this.cleanData();
    }
    let agent = data['agents'].filter(agent => agent.id === this.currentAgentId)[0];
    let user = data['profile'];
    return user && user['name'] == 'I+C+A+S+U';
  }


  /**
   * Check if the current agent has permission to see the Intents Page
   * @return `true` if it has, otherwise returns `false`
   */
   get checkPermissionAccessControl() {
    // nome do agente atual
    let data = localStorage.getItem('hrBotData');
    if(!data || data === 'undefined') return false
    data = this.crypt.decrypt(data);
    data = JSON.parse(data);
    if (!data.hasOwnProperty('agents')) {
      this.toastr.error('Nenhum agente selecionado.', 'Erro!');
      this.cleanData();
    }
    let agent = data['agents'].filter(agent => agent.id === this.currentAgentId)[0];
    let user = data['profile'];

    return agent && agent['segmentation'] && user && user['segmentation'];
  }


  /**
   * Check current Agent's name
   * Return the Agent's name
   */
  get currentAgentData() {
    // nome do agente atual
    let agent = localStorage.getItem('hrBotData');
    if(!agent || agent === 'undefined') return {}
    agent = this.crypt.decrypt(agent);
    agent = JSON.parse(agent);
    if (!agent.hasOwnProperty('agents')) {
      this.toastr.error('Nenhum agente selecionado.', 'Erro!');
      this.cleanData();
    }
    if (this.currentAgentId === 9999) {
      this.currentAgentId = agent['agents'][0].id;
    }
    agent = agent['agents'].filter(agent => agent.id === this.currentAgentId)[0];
    return agent;
  }

  /**
   * Check current Agent's name
   * Return the Agent's name
   */
   get currentUser() {
    // nome do agente atual
    let user = localStorage.getItem('hrBotData');
    if (!user || user === "undefined") {
      return false;
    }
    user = this.crypt.decrypt(user);
    user = JSON.parse(user);

    return user;
  }

  /**
   * Check current Project's ID
   * Return the Project's ID
   */
  get projectId() {
    // nome do agente atual
    let project = localStorage.getItem('hrBotData');

    if (!project || project === 'undefined') {
      this.cleanData();
      this.router.navigate(['/login']);
      return false;
    }
    project = this.crypt.decrypt(project);
    project = JSON.parse(project);
    if (!project.hasOwnProperty('agents')) {
      this.toastr.error('Nenhum agente selecionado.', 'Erro!');
      this.cleanData();
    }
    project = project['agents'].filter(agent => agent.id === this.currentAgentId)[0].project_id;

    return project;
  }

    /**
     * Check current Project's ID
     * Return the Project's ID
     */
    get userEmail() {
      // nome do agente atual
      let user = localStorage.getItem('hrBotData');
      if (!user || user === "undefined") {
        return 'false';
      }
      user = this.crypt.decrypt(user);
      user = JSON.parse(user).email;

      return user;
    }

    set accessToken(accessToken: string) {
      this.cookieService.set('hrBotToken', accessToken);
    }

    get accessToken(): string | null {
      let token = this.cookieService.get('hrBotToken');
      return token;
    }

    get refreshToken(): string | null {
      let token = this.cookieService.get('hrBotRT');
      return token;
    }


  /** sort dict by keys */
  sortOnKeys(dict) {
    const sorted = Object.keys(dict).sort();
    const tempDict = {};
    for (let i = 0; i < sorted.length; i++) {
      tempDict[sorted[i]] = dict[sorted[i]];
    }

    return tempDict;
  }


  cleanData() {
    this.cookieService.delete('hrBotToken');
    this.cookieService.delete('hrBotToken.sig');
    this.cookieService.delete('hrBotRT');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('hrBotData');
    localStorage.removeItem('agentId');
    localStorage.removeItem('hrBotId');
  }

}