import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WebChatComponent } from './web-chat.component';
import { SharedModule } from '../shared/shared.module';
import { ChatModule } from '../chat/chat.module';

@NgModule({
  declarations: [WebChatComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChatModule
  ],
  exports: [WebChatComponent]
})
export class WebChatModule { }
