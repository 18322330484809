import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private reqUrl: string;

  constructor(private http: HttpClient) { }

  addAgent(data): Observable<any> {
    this.reqUrl = `${environment.baseUrl}/agentes`;
    return this.http.post(this.reqUrl, data);
  }

  listAgentAndProfile(): Observable<any> {
    this.reqUrl = `${environment.baseUrl}/agentes`;
    return this.http.get(this.reqUrl);
  }

  getAgent(id): Observable<any> {
    this.reqUrl = `${environment.baseUrl}/agentes/${id}`;
    return this.http.get(this.reqUrl);
  }

  updateAgent(id, data): Observable<any> {
    this.reqUrl = `${environment.baseUrl}/agentes/${id}`;
    return this.http.put(this.reqUrl, data);
  }

  deleteAgent(id): Observable<any> {
    this.reqUrl = `${environment.baseUrl}/agentes/${id}`;
    return this.http.delete(this.reqUrl);
  }

}
