import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    private reqUrl: string;

    constructor(
        private http: HttpClient,
        ) { }

    changePassword(data): Observable<any> {
        this.reqUrl = `${environment.baseUrl}/users-permissions/update-password`;
        return this.http.put(this.reqUrl, data);
    }

    resetPassword(email: string): Observable<any> {
        this.reqUrl = `${environment.baseUrl}/users-permissions/reset-password`;
        return this.http.get(`${this.reqUrl}?email=${email}`);
    }

    getUsers(projectId?: any): Observable<any> {
        this.reqUrl = `${environment.baseUrl}/users?_limit=-1`;
        if (projectId) {
            this.reqUrl += `&project_id=${projectId}`;
        }
        return this.http.get(this.reqUrl);
    }

    addUser(data): Observable<any> {
        this.reqUrl = `${environment.baseUrl}/users`;
        return this.http.post(this.reqUrl, data);
    }

    getUser(id): Observable<any> {
        this.reqUrl = `${environment.baseUrl}/users/${id}`;
        return this.http.get(this.reqUrl);
    }

    updateUser(id, data): Observable<any> {
        this.reqUrl = `${environment.baseUrl}/users/${id}`;
        return this.http.put(this.reqUrl, data);
    }

    deleteUser(id): Observable<any> {
        this.reqUrl = `${environment.baseUrl}/users/${id}`;
        return this.http.delete(this.reqUrl);
    }
}
