import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/common/services/config.service';
import { IssuesRatingService } from 'src/app/issues-rating/issues-rating.service';
import { IssuesService } from 'src/app/issues/issues.service';
import { Chat } from '../../interfaces/chat';

@Component({
  selector: 'app-chat2',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @Input() chats: Chat[] = [];
  @Input() issueSolved: boolean = true;
  @Input() sendAnswer: any;
  @Input() issueData: any;
  @Input() issueId: string;
  currentEmail: string = '';
  isLoaded: boolean = false;
  buttonDisabled: boolean = false;
  fileToUpload: File | null = null;

  constructor(
    private issueService: IssuesService,
    private toastr: ToastrService,
    private configService: ConfigService,
    private issuesRatingService: IssuesRatingService,
    ) {
      this.currentEmail = this.configService.userEmail;
     }


  sendMessage(text) {
    const answer = text.value.trim();
    // impedir que uma resposta nula ou muito pequena seja aceita
    if (!answer || answer.length < 2) return;
    // limpa o campo de texto
    text.value  = '';
    this.chats.push({
      from: 'Você respondeu',
      message: answer,
      time: new Date(),
      email: this.currentEmail,
    });

    this.issueData.email = this.currentEmail ? this.currentEmail : 'RH';
    // grava a resposta no banco de dados e envia um email para o usuario
    this.sendAnswer(answer, this.issueData, this.issueId);
  }

  updateEmailCc(event) {
    let value = event.target.value;
    // if (!value) return this.issueData.cc = null;
    this.issueData.cc = value;
  }

  toggleCcInput(input) {
    if (input.style.display === 'none') return input.style.display = 'inline';
    this.issueData.cc = '';
    return input.style.display = 'none';
  }

  async annexedFile(files){
    this.fileToUpload = files.item(0);
    const data = new FormData();
    data.append('files', this.fileToUpload);
    this.issueData.newFiles = data;
  }

  toggleAnnexedInput(input) {
    if (input.style.display === 'none') return input.style.display = 'inline';
    this.issueData.file = '';
    return input.style.display = 'none';
  }

  ngOnInit() {
    this.isLoaded = true;
    if (this.issueSolved || !this.issueData.user_contact || this.issueData.user_contact == 'Não informado') {
      this.buttonDisabled = true;
    }
    this.issueData.cc = '';
  }

}
